import { Button, ModalR, RenderTitle, PermissionWrapper, po, RenderYesNoSwitcher, FieldWrapper, SelectD, SelectWithSearch, ContainerWrapper } from '../../common';
import { Fragment, useCallback, useEffect, useState } from 'react';
import cls from './ModulesOptionsModal.module.scss';
import { useModulesContext } from '../../../context/modules.context';
import Select from 'react-select';
import { DropdownIndicator, itemArray, operatorArray } from '../../../shared/utility';
import { connect } from 'react-redux';
import { createNewModule, deleteModule, editModule } from '../../../services/module.service';
import { setMessageBar } from '../../../reduxStore/actions/flowActions';
import { store } from '../../../reduxStore/store';
import { isMobile } from 'react-device-detect';
import { MultiSelectWithCheckboxesAndNestedItems_v2 } from '../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';
import { useProcessContext, useProcessStepContext } from '../../../context/processStep.context';
import MessageContainer from '../ProcessesComps/MessageContainer/MessageContainer';
const R = require('ramda');

const ModulesOptionsModal = props => {
	const { onClose, isOpen, companyProcesses, companyPositionsArray, selectedProcessId = '', allModules, components, shadowedContainerStyle = { width: isMobile ? '100%' : '30%' }, onBoardingCompleted, onSave = () => { }, companyData } = props;
	const [modulesContext] = useModulesContext();
	const { name } = modulesContext?.selectedModule || {};
	const [message, setMessage] = useState('');
	const [processValue] = useState(selectedProcessId);
	const [selectedModule, setSelectedModule] = useState(undefined);
	const [optionsValue, setOptionsValue] = useState({ emailOutputs: false, emailTo: [], outputTo: 'component', title: [] });
	const [fieldValue, setFieldValue] = useState(name);
	const [error, setError] = useState('');
	const [procContext] = useProcessContext();
	const [multiselectItems, setMultiselectItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [dLoading, setDloading] = useState(false);
	const [newRule, setNewRule] = useState({ module_id: '', item: '', field_id: '', action: '' });
	const [showOptionsDB, setShowsOptionsDB] = useState(!selectedModule?.id?.length > 0);
	const settingFieldValue = val => {
		setFieldValue(val);
	};
	const [modules] = useState(
		allModules.map(elem => {
			return { label: elem.name, value: elem.id, module: elem, modules: 'Modules' };
		})
	);

	const processesList =
		companyProcesses?.length > 0
			? companyProcesses.map(elem => {
				return { label: elem.name, value: elem, process: elem };
			})
			: [];

	const modulesList =
		allModules?.length > 0
			? allModules.map(elem => {
				return { label: elem.name, value: elem.id, module: elem };
			})
			: [];

	const createModule = (moduleName, process_value, options) => {
		setLoading(true);
		createNewModule(moduleName, process_value, options).subscribe({
			next: data => {
				store.dispatch(setMessageBar({ show: true, message: `You have successfully created module ${moduleName}` }));

				onClose && onClose();
				onSave();
			},
			error: error => {
				if (error) {
					setError('Error found');
				}
			},
		});
	};

	const updateModule = useCallback(
		selectedModule => {
			setLoading(true);
			editModule(selectedModule.id, fieldValue, selectedModule.procedure.id, optionsValue).subscribe({
				next: data => {
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${selectedModule.name}` }));
					setSelectedModule(data);
					onClose && onClose();
				},
				error: error => {
					setError(error.data), setLoading(false);
				},
			});
		},
		[selectedModule, fieldValue, optionsValue]
	);

	const removeModule = useCallback(moduleId => {
		setDloading(true);
		deleteModule(moduleId).subscribe({
			next: () => {
				onClose && onClose();
				store.dispatch(setMessageBar({ show: true, message: `You have successfully deleted module${selectedModule?.name ?? ''}`, color: 'red' }));
			},
			error: () => {
				setError('Error found');
			},
		});
	}, []);

	useEffect(() => {
		let items = [];

		procContext.steps?.filter(step => {
			step.component_blueprints.filter(bpc => {
				bpc.selected_fields.filter(field => {
					items = [
						...items,
						{
							label: field.field_name,
							value: field.field_name,
							blueprint_name: bpc.name,
							blueprint_id: bpc.id,
						},
					];
				});
			});
		});
		items = R.uniqWith(R.eqProps('label'))(items);
		setMultiselectItems([
			...R.sortWith([R.ascend(R.prop('blueprint_name'))])(items),
			{
				label: 'UUID',
				value: 'UUID',
				blueprint_name: 'Others',
			},
			{
				label: 'Created Date',
				value: 'Created Date',
				blueprint_name: 'Others',
			},
		]);
	}, [procContext]);
	return (
		<ModalR isOpen={isOpen} blured={true} scrollable={true} shadowedContainerStyle={shadowedContainerStyle}>
			{props.children}
			<div className={cls.host}>
				<div className={cls.target}>
					{onBoardingCompleted && (
						<p className={cls.title}>
							{selectedModule?.id?.length > 0 ? 'edit' : 'create new'} {'module'}
						</p>
					)}
					{onBoardingCompleted && (
						<p className={cls.subtitle} style={{ display: selectedProcessId !== '' ? '' : 'none' }}>
							{`for the ${processesList.find(p => p.process.id === selectedProcessId)?.label} process`}
						</p>
					)}
					<OptionSection>
						{onBoardingCompleted && <RenderTitle title={'Select Module to Edit'} />}
						{onBoardingCompleted && (
							<Select
								onFocus={() => setMessage('')}
								isSearchable={false}
								key={'Module'}
								styles={customStyles}
								placeholder={'Select a module to edit instead...'}
								components={{ DropdownIndicator }}
								options={[{ label: 'New Module', value: 'new' }, ...modulesList.filter(mod => mod.module.procedure.id === selectedProcessId)]}
								onChange={select => {
									if (select.module) {
										setSelectedModule(select.module);
										settingFieldValue(select.module.name);
										setOptionsValue(select.module.options);
										setShowsOptionsDB(false);
									} else {
										setSelectedModule(undefined);
										settingFieldValue('');
										setOptionsValue({ emailOutputs: false, emailTo: [], outputTo: 'component', title: [] });
										setShowsOptionsDB(false);
									}
								}}
							/>
						)}

						<RenderTitle title={'Name of module'} />
						<input type="text" name="element-name" value={fieldValue} onChange={e => settingFieldValue(e.target.value)} onClick={() => setShowsOptionsDB(true)} className={cls.elementTextInput} style={{ marginBottom: '10px' }} />

						{fieldValue.length > 3 && (
							<div>
								<RenderTitle title={'Output Title Name'} />

								{showOptionsDB && (
									<MultiSelectWithCheckboxesAndNestedItems_v2
										tagEmplacement={'inlined'}
										items={multiselectItems}
										groupedBy={'blueprint_name'}
										groupname={'blueprint_name'}
										triggerType={'chevron'}
										initialSelectedValues={optionsValue?.title?.map(d => {
											return multiselectItems.find(item => item.value === d);
										})}
										className={cls.peoplePositionsSelect}
										inputClassName={cls.peoplePositionsSelectInput}
										molclassname={cls.peoplePositionsSelectMenuOverlay}
										moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
										onChange={selection => {
											setOptionsValue({ ...optionsValue, title: selection?.map(i => i?.value) });
										}}
									/>
								)}

								<input
									type="text"
									name="element-name"
									value={optionsValue?.title?.filter(d => {
										return `${multiselectItems.find(item => item?.label === d)?.value}`;
									})}
									onClick={() => setShowsOptionsDB(true)}
									onChange={e => settingFieldValue(e.target.value)}
									className={cls.elementTextInput}
									style={{ display: showOptionsDB ? 'none' : '', marginBottom: '10px' }}
								/>

								{optionsValue.emailOutputs == true && <RenderTitle title={'Email module output to'} />}
								{optionsValue.emailOutputs == true && (
									<MultiSelectWithCheckboxesAndNestedItems_v2
										tagEmplacement={'inlined'}
										items={companyPositionsArray}
										groupedBy={'department'}
										groupname={'department'}
										triggerType={'chevron'}
										initialSelectedValues={optionsValue?.emailTo.map(d => {
											return companyPositionsArray.find(item => item.value === d);
										})}
										className={cls.peoplePositionsSelect}
										inputClassName={cls.peoplePositionsSelectInput}
										molclassname={cls.peoplePositionsSelectMenuOverlay}
										moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
										onChange={selection => {
											setOptionsValue(prev => {
												return { ...prev, emailTo: selection?.map(i => i?.value) };
											});
										}}
									/>
								)}

								<br />

								<RenderTitle title={'Module Processors'} />
								<MultiSelectWithCheckboxesAndNestedItems_v2
									tagEmplacement={'inlined'}
									searchable={false}
									items={companyPositionsArray}
									groupedBy={'department'}
									groupname={'department'}
									triggerType={'chevron'}
									initialSelectedValues={optionsValue?.processors?.map(d => {
										return companyPositionsArray.find(item => item.value === d);
									})}
									className={cls.peoplePositionsSelect}
									inputClassName={cls.peoplePositionsSelectInput}
									molclassname={cls.peoplePositionsSelectMenuOverlay}
									moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
									onChange={selection => {
										setOptionsValue(prev => {
											return { ...prev, processors: selection?.map(i => i?.value) };
										});
									}}
								/>

								<br />

								<RenderTitle title={'Custom save message'} />
								<textarea
									type="text"
									name="element-name"
									value={optionsValue?.message ?? ''}
									onChange={e =>
										setOptionsValue(prev => {
											return { ...prev, message: e.target.value };
										})
									}
									onClick={() => setShowsOptionsDB(true)}
									className={cls.elementTextInput}
									style={{ height: '100px', marginBottom: '10px' }}
								/>
								<RenderTitle title={'Custom save button label'} />
								<input
									type="text"
									name="element-name"
									value={optionsValue?.save ?? ''}
									onClick={() => setShowsOptionsDB(true)}
									onChange={e =>
										setOptionsValue(prev => {
											return { ...prev, save: e.target.value };
										})
									}
									className={cls.elementTextInput}
								/>
								<br />
								<br />
								<RenderTitle title={'Global filter value'} />
								<input
									type="text"
									name="element-global"
									value={optionsValue?.global ?? ''}
									onClick={() => setShowsOptionsDB(true)}
									onChange={e =>
										setOptionsValue(prev => {
											return { ...prev, global: e.target.value };
										})
									}
									className={cls.elementTextInput}
								/>
								<br />
								<br />
								<RenderTitle title={'Background Image'} />
								{optionsValue?.background && <div className={cls.controls} >
									<img alt="lg" src={optionsValue?.background} style={{ width: '50%', height: '50%', cursor: 'pointer' }} />
								</div>}
								<textarea
									type="text"
									name="element-image"
									value={optionsValue?.background ?? ''}
									onChange={e =>
										setOptionsValue(prev => {
											return { ...prev, background: e.target.value };
										})
									}
									onClick={() => setShowsOptionsDB(true)}
									className={cls.elementTextInput}
									style={{ height: '100px', marginBottom: '10px' }}
								/>



								<RenderTitle title={'Custom home page message'} />
								<textarea
									type="text"
									name="element-name"
									value={optionsValue?.home ?? ''}
									onChange={e =>
										setOptionsValue(prev => {
											return { ...prev, home: e.target.value };
										})
									}
									onClick={() => setShowsOptionsDB(true)}
									className={cls.elementTextInput}
									style={{ height: '100px' }}
								/>

								<br />

								{!optionsValue?.forSpecificUsers && <>
									<RenderTitle title={'Share transactions made to these positions'} />
									<MultiSelectWithCheckboxesAndNestedItems_v2
										tagEmplacement={'inlined'}
										searchable={false}
										items={companyPositionsArray}
										groupedBy={'department'}
										groupname={'department'}
										triggerType={'chevron'}
										initialSelectedValues={optionsValue?.shares?.map(d => {
											return companyPositionsArray.find(item => item.value === d);
										})}
										className={cls.peoplePositionsSelect}
										inputClassName={cls.peoplePositionsSelectInput}
										molclassname={cls.peoplePositionsSelectMenuOverlay}
										moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
										onChange={selection => {
											setOptionsValue(prev => {
												return { ...prev, shares: selection?.map(i => i?.value) };
											});
										}}
									/>
								</>}

								<ContainerWrapper ungrouped title={'Download supporting data from:'} columned withAckButton={false}>
									<MultiSelectWithCheckboxesAndNestedItems_v2
										id={'Modules'}
										tagEmplacement={'inlined'}
										searchable={false}

										items={modules}
										groupedBy={'modules'}
										groupname={'modules'}
										triggerType={'chevron'}
										initialSelectedValues={optionsValue?.supporting?.map(d => {
											return modules.find(item => item.value === d);
										})}
										className={cls.peoplePositionsSelect}
										inputClassName={cls.peoplePositionsSelectInput}
										molclassname={cls.peoplePositionsSelectMenuOverlay}
										moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
										onChange={value => {
											if (value.length > 0) {

												setOptionsValue(prev => {
													return { ...prev, supporting: value?.map(i => i?.value) };
												})
											} else {
												setOptionsValue(prev => {
													return { ...prev, supporting: [] };
												})
											}
										}}
									/>
								</ContainerWrapper>


								<RenderTitle title={'Download users from these positions'} />
								<MultiSelectWithCheckboxesAndNestedItems_v2
									tagEmplacement={'inlined'}
									searchable={false}
									items={companyPositionsArray}
									groupedBy={'department'}
									groupname={'department'}
									triggerType={'chevron'}
									initialSelectedValues={optionsValue?.positions?.map(d => {
										return companyPositionsArray.find(item => item.value === d);
									})}
									className={cls.peoplePositionsSelect}
									inputClassName={cls.peoplePositionsSelectInput}
									molclassname={cls.peoplePositionsSelectMenuOverlay}
									moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
									onChange={selection => {
										setOptionsValue(prev => {
											return { ...prev, positions: selection?.map(i => i?.value) };
										});
									}}
								/>
								<br />
								{optionsValue?.asMobileApp && <>
									<RenderTitle title={'Number of minutes before hiding unassigned transaction (0-disabled)'} />
									<br />
									<input
										type="number"
										name="element-name"
										value={optionsValue?.activeFor ?? '0'}
										onChange={e =>
											setOptionsValue(prev => {
												return { ...prev, activeFor: e.target.value };
											})
										}
										className={cls.elementTextInput}
									/>
									<br />
								</>}
								<br />

								<RenderTitle title={'Show Ads from'} />
								<Select
									onFocus={() => setMessage('')}
									isSearchable={false}
									key={'Module'}
									styles={customStyles}
									components={{ DropdownIndicator }}
									value={modulesList.find(mod => mod?.value === optionsValue?.ads) ?? {}}
									options={[...modulesList.filter(mod => (mod.module.procedure.id !== selectedProcessId && mod.module.options?.asGraphical == true))]}
									onChange={select => {
										if (select.module) {
											setOptionsValue(prev => {
												return { ...prev, ads: select.module.id };
											});
										}
									}}
								/>


								<RenderTitle title={'Computations (if multiple inputs is allowed in workflow)'} />
								{optionsValue?.computes?.length > 0 && <>

									{optionsValue?.computes?.map((compute, index) => {
										return (
											<div key={index}>
												<p>{`Rule #${index + 1}: ${itemArray.find(it => it.value === compute.item)?.label} (${allModules.find(it => it.id === compute.module_id)?.name})`}</p>
												<FieldWrapper
													id={index}
													defaultHighlighted={true}
													ungrouped={true}
													withAckButton={false}
													useContext={useProcessStepContext}
													readOnly={false}
													value={
														<div style={{ padding: '0 30px 0 30px', color: 'var(--app-color-gray-dark)', fontFamily: 'var(--app-text-main-font)', fontSize: '16px', fontWeight: '500' }}>
															<p>{`Target Field to compute : ${components.find(com => com.module_id === compute.module_id)?.component_fields?.find(cf => cf.procedure_step_blueprint_field_id === compute.field_id)?.field_name} `} </p>
															<p>{`Action : ${operatorArray.find(it => it.value === compute.action)?.label}`} </p>
														</div>
													}
												>
													<div style={{ width: '100%', padding: '20px' }}>
														<ValueSelect
															disable={true}
															defaultConfig={compute}
															onConfigSet={config => {
																const array = optionsValue?.computes?.length > 0 ? [...optionsValue.computes.filter(com => com.item !== config.item), config] : [config];
																setOptionsValue(prev => {
																	return { ...prev, computes: array };
																});
															}}
															allModules={allModules}
															components={components}
														/>
														<Button
															light
															title={'Remove'}
															clicked={() => {
																setOptionsValue(prev => {
																	return { ...prev, computes: optionsValue.computes.filter(com => com.item !== compute.item) };
																});
															}}
														/>
													</div>
												</FieldWrapper>
											</div>
										);
									})
									}
								</>}
								<FieldWrapper id={'transactions'} defaultHighlighted={true} ungrouped={true} withAckButton={false} useContext={useProcessStepContext} readOnly={false} value={<div style={{ textAlign: 'center' }}>{'Add Rule'}</div>}>
									<div style={{ width: '100%', padding: '20px' }}>
										<ValueSelect
											defaultConfig={{ module_id: 'new', field_id: 'new', item: 'new', action: 'new' }}
											onConfigSet={config => {
												setNewRule(config);
											}}
											allModules={allModules}
											components={components}
										/>
										{newRule && (
											<Button
												light
												title={'Add'}
												clicked={() => {
													if (newRule.module_id !== 'new' && newRule?.item !== 'new' && newRule?.field_id !== 'new' && newRule?.action !== 'new') {
														if (newRule.module_id !== '' && newRule?.item !== '' && newRule?.field_id !== '' && newRule?.action !== '') {
															const array = optionsValue?.computes?.length > 0 ? [...optionsValue.computes.filter(com => com.item !== newRule.item), newRule] : [newRule];
															setOptionsValue(prev => {
																return { ...prev, computes: array };
															});
														}
													}
												}}
											/>
										)}
									</div>
								</FieldWrapper>
								<br />

								<RenderTitle title={'Other Module Options'} />
								<FieldWrapper
									id={'options'}
									defaultHighlighted={true}
									ungrouped={true}
									withAckButton={false}
									useContext={useProcessStepContext}
									readOnly={false}
									value={
										<div style={{ padding: '0 30px 0 30px', color: 'var(--app-color-gray-dark)', fontFamily: 'var(--app-text-main-font)', fontSize: '16px', fontWeight: '500' }}>
											<p>{`Strict edit : ${optionsValue?.strict ? 'Yes' : 'No'}`} </p>
											<p>{`Read only for non-admins : ${optionsValue?.readOnly ? 'Yes' : 'No'}`} </p>
											<p>{`Hide for non-admins : ${optionsValue?.hide ? 'Yes' : 'No'}`} </p>
											<p>{`Is master data : ${optionsValue?.isMaster ? 'Yes' : 'No'}`} </p>
											<p>{`Allow save in cart : ${optionsValue?.saveCart ? 'Yes' : 'No'}`} </p>
											<p>{`Creator can cancel : ${optionsValue?.canCancel ? 'Yes' : 'No'}`} </p>
											{/* <p>{`One active transaction only : ${optionsValue?.oneActive ? 'Yes' : 'No'}`} </p> */}
											<p>{`Allow import : ${optionsValue?.allowImport ? 'Yes' : 'No'}`} </p>
											<p>{`Email outputs generated : ${optionsValue?.emailOutputs ? 'Yes' : 'No'}`} </p>
											{optionsValue?.emailOutputs == true && <p>{`Select email recipients before save : ${optionsValue?.selectiveEmail ? 'Yes' : 'No'}`} </p>}
											<p>{`Allow sharing to specific users : ${optionsValue?.forSpecificUsers ? 'Yes' : 'No'}`} </p>
											<p>{`Display as graphical in dashboard : ${optionsValue?.asGraphical ? 'Yes' : 'No'}`} </p>
											<p>{`Start as single mobile app : ${optionsValue?.asMobileApp ? 'Yes' : 'No'}`} </p>
											<p>{`Require credits for transactions made : ${optionsValue?.credits ? 'Yes' : 'No'}`} </p>
											<p>{`Save location information : ${optionsValue?.location ? 'Yes' : 'No'}`} </p>
											<p>{`Save single data in device only : ${optionsValue?.single ? 'Yes' : 'No'}`} </p>
										</div>
									}
								>
									<div style={{ padding: '0 20px 0 20px' }}>
										<RenderYesNoSwitcher
											{...{
												id: 'strictEdit',
												title: 'Strict Edit?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.strict ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, strict: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'readOnly',
												title: 'Read only for non-admins?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.readOnly ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, readOnly: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'hideOnly',
												title: 'Hide for non-admins?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.hide ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, hide: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'isMasterData',
												title: 'Is master data?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue?.isMaster}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, isMaster: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'saveDraftCart',
												title: 'Allow save in cart?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue?.saveCart}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, saveCart: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'cancelTransact',
												title: 'Creator can cancel?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue?.canCancel}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, canCancel: val };
												});
											}}
										/>
										{/* <RenderYesNoSwitcher
											{...{
												id: 'OneActiveOnly',
												title: 'One active transaction only?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue?.oneActive}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, oneActive: val };
												});
											}}
										/> */}
										<RenderYesNoSwitcher
											{...{
												id: 'duplicateOutputs',
												title: 'Allow copy of transaction?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue?.copyOutputs}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, copyOutputs: val };
												});
											}}
										/>

										<RenderYesNoSwitcher
											{...{
												id: 'allowImport',
												title: 'Allow import?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue?.allowImport}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, allowImport: val };
												});
											}}
										/>

										<RenderYesNoSwitcher
											{...{
												id: 'emailOutputs',
												title: 'Email outputs generated?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.emailOutputs}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, emailOutputs: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'selectiveEmail',
												title: 'Select email recipients before save?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto', display: optionsValue.emailOutputs == true ? '' : 'none' }}
											value={optionsValue.selectiveEmail}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, selectiveEmail: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'forSpecificUsers',
												title: 'Allow sharing to specific users?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.forSpecificUsers ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, forSpecificUsers: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'displayGraphical',
												title: 'Display as graphical in dashboard?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.asGraphical ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, asGraphical: val };
												});
											}}
										/>
										{
											<RenderYesNoSwitcher
												{...{
													id: 'asSingleMobileApp',
													title: 'Start as single mobile app?',
													ungrouped: true,
													withAckButton: false,
												}}
												style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
												value={optionsValue.asMobileApp ? true : false}
												toggleSelected={val => {
													setOptionsValue(prev => {
														return { ...prev, asMobileApp: val };
													});

											
												}}
											/>
										}

										<RenderYesNoSwitcher
											{...{
												id: 'requireCredits',
												title: 'Require credits for transactions made?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.credits ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, credits: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'allowRewards',
												title: 'Transaction earns reward?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.rewards ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, rewards: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'Save location information',
												title: 'Save location information?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue.location ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, location: val };
												});
											}}
										/>
										<RenderYesNoSwitcher
											{...{
												id: 'Save single data in device only',
												title: 'Save single data in device only?',
												ungrouped: true,
												withAckButton: false,
											}}
											style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
											value={optionsValue?.single ? true : false}
											toggleSelected={val => {
												setOptionsValue(prev => {
													return { ...prev, single: val };
												});
											}}
										/>
									</div>
								</FieldWrapper>
							</div>
						)}
					</OptionSection>

					{error?.length > 0 && <MessageContainer message={error} onClick={() => setError('')} />}
					{message?.length > 0 && (
						<span className={cls.messageContainer} onClick={() => setMessage('')}>
							{message}
						</span>
					)}
				</div>
				<div className={onBoardingCompleted ? cls.controls : cls.controls2}>
					{!onBoardingCompleted && <Button clear title={''} clicked={() => { }} />}
					{onClose && <Button title={onBoardingCompleted ? 'Close' : 'Back'} clicked={onClose} />}
					{!selectedModule?.id?.length > 0 && fieldValue && optionsValue && onBoardingCompleted && <Button light title="Save" loading={loading} clicked={() => createModule(fieldValue, processValue, optionsValue)} />}
					{!selectedModule?.id?.length > 0 && fieldValue && optionsValue && !onBoardingCompleted && <Button title="Save" loading={loading} clicked={() => createModule(fieldValue, processValue, optionsValue)} />}

					{selectedModule?.id?.length > 0 && <Button light title="Update" loading={loading} clicked={() => updateModule(selectedModule)} />}
					{components.filter(component => component.module_id === selectedModule?.id).length === 0 && selectedModule?.id?.length > 0 && (
						<PermissionWrapper permissions={[po.blueprints.statuses.delete]}>
							<Button light title="Delete" clicked={() => removeModule(selectedModule.id)} loading={dLoading} />
						</PermissionWrapper>
					)}
				</div>
			</div>
		</ModalR>
	);
};

export const ValueSelect = props => {
	const { disable = false, onConfigSet = () => { }, defaultConfig, defaultModule, components = [], allModules = [] } = props;
	const [selected, setSelected] = useState(undefined);
	const [selectedComponent, setSelectedComponent] = useState(defaultModule ? { value: '' } : undefined);
	const [config, setConfig] = useState(defaultConfig ?? { module_id: '', field_id: '', item: '', action: '' });
	const [selectedComponents, setSelectedComponents] = useState(components.filter(com => com.module_id === (defaultConfig?.module_id ?? '$#')));
	const [modules] = useState(
		allModules.map(elem => {
			return { label: elem.name, value: elem.id, module: elem };
		})
	);

	return (
		<>
			{!disable && <RenderTitle title={'Select transaction field to compute (A)'} />}

			{!disable && (
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={itemArray}
						defaultSelectedItem={itemArray.find(it => it.value === (defaultConfig?.item ?? config?.item))}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selected => {
							if (selected) {
								setConfig({ ...config, item: selected.value });
								onConfigSet({ ...config, item: selected.value });
							}
						}}
					/>
				</div>
			)}
			{!disable && <RenderTitle title={'Module where to conduct cross-value computations:'} />}
			{!disable && (
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						defaultSelectedItem={modules.find(it => it.value === (defaultConfig?.module_id ?? '#$'))}
						triggerType={'chevron'}
						items={modules}
						styles={customStyles}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							if (selection) {
								setSelected(undefined);
								setTimeout(() => setSelected(selection), 10);

								setConfig({ ...config, module_id: selection.value, field_id: '', action: '' });
								onConfigSet({ ...config, module_id: selection.value, field_id: '', action: '' });

								setConfig({ ...config, module_id: selection.value });
								onConfigSet({ ...config, module_id: selection.value });

								setSelectedComponents(components?.filter(com => com.module_id === selection.value));
							}
						}}
					/>
				</div>
			)}
			{(selected || defaultConfig?.field_id) && (
				<>
					<RenderTitle title={'Field (B) from module affected by changes in A'} />
					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={
								selectedComponents.length > 0
									? selectedComponents[0].component_fields
										.filter(cf => cf.data_type_name === 'Number')
										.map(com => {
											return { label: com.field_name, value: com.procedure_step_blueprint_field_id };
										})
									: []
							}
							defaultSelectedItem={
								selectedComponents.length > 0
									? selectedComponents[0].component_fields
										.map(com => {
											return { label: com.field_name, value: com.procedure_step_blueprint_field_id };
										})
										.find(cf => cf.value === (defaultConfig?.field_id ?? '#$'))
									: undefined
							}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selected => {
								if (selected) {
									setSelectedComponent(selected);
									setConfig({ ...config, field_id: selected.value });
									onConfigSet({ ...config, field_id: selected.value });
								}
							}}
						/>
					</div>
				</>
			)}
			{(selectedComponent || defaultConfig?.action) && (
				<>
					<RenderTitle title={'Select action to be performed:'} />

					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={operatorArray}
							defaultSelectedItem={operatorArray.find(it => it.value === (defaultConfig?.action ?? config?.action))}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selected => {
								if (selected) {
									setConfig({ ...config, action: selected.value });
									onConfigSet({ ...config, action: selected.value });
								}
							}}
						/>
					</div>
				</>
			)}
		</>
	);
};

export const customStyles = {
	control: (provided, _state) => ({
		...provided,
		boxShadow: 'none',
		'&:hover': {
			border: '1px solid var(--app-color-gray-lighter)',
		},
		position: 'relative',
		height: '60px',
		width: '101%',
		border: 'var(--app-color-gray-lighter) solid 1px',
		borderRadius: '8px',
		padding: '3px',
		fontFamily: 'var(--app-text-main-font)',
		fontSize: '1.4em',
		color: '#4A4A4A',
		textAlign: 'left',
		flex: '1 1 auto',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		alignSelf: 'center',
		margin: '0px 0 0 0 ',
		marginBottom: '20px',
	}),
	input: (provided, _state) => ({
		...provided,
		fontFamily: 'var(--app-text-main-font)',
		color: '#4A4A4A',
		width: '100%',
		textAlign: 'left',
		fontSize: '0.8em',
		alignSelf: 'center',
		padding: '0',
		marginLeft: 0,
		textIndent: '0',
	}),
	menu: (provided, _state) => ({
		...provided,
		justifyContent: 'center',
		alignItems: 'center',
		verticalTextAlign: 'center',
		alignContent: 'center',
		fontFamily: 'var(--app-text-main-font)',
	}),
	menulist: (provided, _state) => ({
		...provided,
		justifyContent: 'center',
		alignItems: 'center',
		verticalTextAlign: 'center',
		alignContent: 'center',
		fontFamily: 'var(--app-text-main-font)',
	}),
	option: (provided, _state) => ({
		...provided,
		minHeight: 15,
		paddingTop: 0,
		fontSize: '1.1em',
		verticalTextAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontFamily: 'var(--app-text-main-font)',
	}),
	placeholder: (provided, _state) => ({
		...provided,
		fontSize: '0.75em',
		lineHeight: '20px',
		color: '#4A4A4A',
		fontWeight: 400,
		overflow: 'hidden',
		fontFamily: 'var(--app-text-main-font)',
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		return {
			...provided,
			opacity,
			transition,
			marginLeft: '0px',
			fontSize: '0.75em',
			lineHeight: '20px',
			color: '#4A4A4A',
			fontFamily: 'var(--app-text-main-font)',
		};
	},
	indicatorsContainer: (styles, { _data }) => ({
		...styles,
		marginTop: 'auto',
		marginBottom: 'auto',
		color: '#4A4A4A',
		size: 7,
		':hover': { color: 'gray' },
		width: '65px',
		height: '45px',
		fontFamily: 'var(--app-text-main-font)',
	}),
};

export const OptionSection = props => {
	const { children } = props;
	return <div className={cls.optionSection}>{children}</div>;
};

const mapStateToProps = store => {
	const { companyProcesses, companyPositionsArray, allModules, components, onBoardingCompleted, companyData } = store.flowReducer;
	return { companyProcesses, companyPositionsArray, allModules, components, onBoardingCompleted, companyData };
};

export default connect(mapStateToProps)(ModulesOptionsModal);
