import { createRef } from 'react';
import { useProcessStepContext } from '../../../../context/processStep.context';

export const scrollableDivRef = createRef(null);

export const stepSchema = {
	parentSteps: {
		id: 'parentStep',
		title: 'Parent Step',
		placeholder: 'Select steps here...',
		defaultHighlighted: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	stepName: {
		id: 'stepName',
		title: 'Step Name',

		defaultHighlighted: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},

	guidance: {
		id: 'guidance',
		title: 'Description',

		defaultHighlighted: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	rank: {
		id: 'rank',
		title: 'Rank',

		defaultHighlighted: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	newComponents: {
		id: 'newComponents',
		title: 'Components to be used in this step',
		defaultHighlighted: true,
		readOnly: true,
		otherContent: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	pathwayOptions: {
		id: 'pathwayOptions',
		title: 'Branch to the following steps',
		defaultHighlighted: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},

	isFinalStep: {
		id: 'isFinalStep',
		title: 'Is this the final step of this workflow?',
		ungrouped: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	stepTemplate: {
		id: 'stepTemplate',
		title: 'Custom Template',
		defaultHighlighted: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	showStepAll: {
		id: 'showStep',
		title: 'Show step to all?',
		ungrouped: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	showStepAuthor: {
		id: 'showStepAuthor',
		title: 'Only show step to author?',
		ungrouped: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	showAsPopup: {
		id: 'showAsPopup',
		title: 'Show step as popup?',
		ungrouped: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
	deleteOnBack: {
		id: 'deleteOnBack',
		title: 'Delete step inputs made on back?',
		ungrouped: true,
		withAckButton: false,
		useContext: useProcessStepContext,
	},
};
