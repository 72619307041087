import { Button, ModalR, RenderTitle, Spinner, MultiselectWithSearchR } from '../../../common';
import { useCallback, useEffect, useState } from 'react';
import cls from './ProcessOptionsModal.module.scss';
import Select from 'react-select';
import { DropdownIndicator } from '../../../../shared/utility';
import { connect } from 'react-redux';
import { createNewProcess, getProcessById, shareProcessToSeveralPositions, updatingProcessById } from '../../../../services/processes.service';
import { setMessageBar, setSelectedProcess } from '../../../../reduxStore/actions/flowActions';
import { store } from '../../../../reduxStore/store';
import { isMobile } from 'react-device-detect';
import { useProcessContext, pat } from '../../../../context/processStep.context';

import MessageContainer from '../../ProcessesComps/MessageContainer/MessageContainer';
import { first, tap } from 'rxjs';
import { getAllPositions } from '../../../../services/company.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
const R = require('ramda');

const ProcessOptionsModal = props => {
	const { onClose = () => { }, onCreate = () => { }, isOpen, companyProcesses, selectedProcess, selectedProcessId = '', shadowedContainerStyle, onBoardingCompleted, companyData, onSkip, companyPositionsArray } = props;
	const [onEdit, setOnEdit] = useState(selectedProcess?.id !== '' ? true : false);
	const [message, setMessage] = useState('');
	const [selectedProc, setSelectedProc] = useState(selectedProcess ? selectedProcess : undefined);
	const [nameValue, setNameValue] = useState(selectedProcess ? selectedProcess.name : '');
	const [descriptionValue, setDescriptionValue] = useState(selectedProcess ? selectedProcess.description : '');
	const [error, setError] = useState('');
	const [, setProcContext] = useProcessContext();
	const [loading, setLoading] = useState(false);
	const [sLoading, setSloading] = useState(false);

	const [loadingPositions, setLoadingPositions] = useState(false);
	const [positions, setPositions] = useState(companyPositionsArray ?? []);
	const [initialSelectedPositions, setInitialSelectedPositions] = useState(selectedProcess?.shared_positions?.map(i => ({ label: i?.name, value: i.id })) ?? []);
	const [loadingInitialPos, setLoadingInitialPos] = useState(true);
	const [selectedPositions, setSelectedPositions] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		if (onEdit == true) {
			getProcessById(selectedProcessId)
				.pipe(first())
				.subscribe({
					next: data => {
						const initialPos = data?.shared_positions?.map(i => ({ label: i?.name, value: i.id })) ?? [];
						setInitialSelectedPositions([...initialPos]);
						setLoadingInitialPos(false);
					},
					error: _error => {
						setLoadingInitialPos(false);
					},
				});

			if (companyPositionsArray?.length == 0) {
				setLoadingPositions(true);
				setLoadingInitialPos(true);

				getAllPositions()
					.pipe(first())
					.subscribe({
						next: dataPositions => {
							setPositions(dataPositions?.map(i => ({ label: i?.name, value: i.id })));
							setLoadingPositions(false);
						},
						error: _error => {
							setLoadingPositions(false);
						},
					});
			}

		}
	}, [onEdit, selectedProcessId]);

	const onSharePressed = useCallback(() => {
		if (selectedPositions?.length == 0 && companyData?.status !== 'superadmin') {
			return setErrorMessage('Select at least a position to share with');
		}
		setLoading(true);
		setErrorMessage('');

		if (companyData?.status !== 'superadmin') {
			shareProcessToSeveralPositions(
				selectedProcessId,
				selectedPositions?.map(i => i?.value)
			)
				.pipe(
					first(),
					tap({
						next: () => { },
						complete: () => { },
					})
				)
				.subscribe({
					next: _data => {
						updateProcess();
					},
					error: error => {
						setErrorMessage(transformErrorResponse(error));
					},
				});
		} else {
			updateProcess();
		}
	}, [selectedProcessId, selectedPositions, nameValue, descriptionValue]);

	const transformErrorResponse = useCallback(
		error => {
			if (!error?.data?.errors && error.data?.message) return error.data?.message;
			else if (error?.data?.errors) {
				var errorIDRegex = /^(?:\D*\d)/g;
				const errors =
					Object.values(error?.data?.errors)
						?.map(err => {
							const receivedErrorsArray = err?.map(e => {
								const numb = e.match(/\d/g)[0];
								return e?.replace(errorIDRegex, selectedPositions[numb]?.label) + '\n';
							});
							return receivedErrorsArray?.join('');
						})
						?.join('') + error.data?.message;
				return errors;
			}
			return '';
		},
		[selectedPositions]
	);

	const processesList =
		companyProcesses?.length > 0
			? companyProcesses.map(elem => {
				return { label: elem.name, value: elem, process: elem };
			})
			: [];

	const createProcess = () => {
		setProcContext({
			type: pat.setAllSteps,
			value: [],
		});
		setLoading(true);
		createNewProcess(nameValue, descriptionValue, selectedProc?.type)
			.pipe(first())
			.subscribe({
				next: data => {
					setLoading(false);
					store.dispatch(setSelectedProcess(data));
					setProcContext({ type: pat.addNewStep, value: data.id });
					onCreate();
				},
				error: error => { },
				complete: () => { },
			});
	};

	const updateProcess = useCallback(() => {
		setLoading(true);
		updatingProcessById(selectedProc?.id, nameValue, descriptionValue, selectedProc?.type)
			.pipe(first())
			.subscribe({
				next: data => {
					setLoading(false);
					store.dispatch(setSelectedProcess(data));
					onBoardingCompleted ? onClose?.() : onCreate();
				},
			});
	}, [selectedProc, nameValue, descriptionValue]);

	const customStyles = {
		control: (provided, _state) => ({
			...provided,
			boxShadow: 'none',
			'&:hover': {
				border: '1px solid var(--app-color-gray-lighter)',
			},
			position: 'relative',
			height: '60px',
			width: '101%',
			border: 'var(--app-color-gray-lighter) solid 1px',
			borderRadius: '8px',
			padding: '3px',
			fontFamily: 'var(--app-text-main-font)',
			fontSize: '1.4em',
			color: '#4A4A4A',
			textAlign: 'left',
			flex: '1 1 auto',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			alignSelf: 'center',
			margin: '0px 0 0 0 ',
			marginBottom: '20px',
		}),
		input: (provided, _state) => ({
			...provided,
			fontFamily: 'var(--app-text-main-font)',
			color: '#4A4A4A',
			width: '100%',
			textAlign: 'left',
			fontSize: '0.8em',
			alignSelf: 'center',
			padding: '0',
			marginLeft: 0,
			textIndent: '0',
		}),
		menu: (provided, _state) => ({
			...provided,
			justifyContent: 'center',
			alignItems: 'center',
			verticalTextAlign: 'center',
			alignContent: 'center',
			fontFamily: 'var(--app-text-main-font)',
		}),
		menulist: (provided, _state) => ({
			...provided,
			justifyContent: 'center',
			alignItems: 'center',
			verticalTextAlign: 'center',
			alignContent: 'center',
			fontFamily: 'var(--app-text-main-font)',
		}),
		option: (provided, _state) => ({
			...provided,
			minHeight: 15,
			paddingTop: 0,
			fontSize: '1.1em',
			verticalTextAlign: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			fontFamily: 'var(--app-text-main-font)',
		}),
		placeholder: (provided, _state) => ({
			...provided,
			fontSize: '0.75em',
			lineHeight: '20px',
			color: '#4A4A4A',
			fontWeight: 400,
			overflow: 'hidden',
			fontFamily: 'var(--app-text-main-font)',
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return {
				...provided,
				opacity,
				transition,
				marginLeft: '0px',
				fontSize: '0.75em',
				lineHeight: '20px',
				color: '#4A4A4A',
				fontFamily: 'var(--app-text-main-font)',
			};
		},
		indicatorsContainer: (styles, { _data }) => ({
			...styles,
			marginTop: 'auto',
			marginBottom: 'auto',
			color: '#4A4A4A',
			size: 7,
			':hover': { color: 'gray' },
			width: '65px',
			height: '45px',
			fontFamily: 'var(--app-text-main-font)',
		}),
	};
	return (
		<ModalR isOpen={isOpen} blured={true} scrollable={true} shadowedContainerStyle={shadowedContainerStyle}>
			<div className={cls.host}>
				<div className={cls.target}>
					<p className={cls.title} style={{ display: onBoardingCompleted ? '' : 'none' }}>
						{selectedProcess?.id?.length > 0 ? 'edit' : 'create new'} {'workflow'}
					</p>
					<OptionSection>
						{props.children}
						<RenderTitle title={'Name of workflow'} />
						<input type="text" name="element-name" value={nameValue} onChange={e => setNameValue(e.target.value)} className={cls.elementTextInput} style={{ marginBottom: '10px' }} />
						<RenderTitle title={'Description of workflow'} />
						<textarea type="text" name="element-name" value={descriptionValue} onChange={e => setDescriptionValue(e.target.value)} className={cls.elementTextInput} style={{ marginBottom: '10px', height: '100px' }} />
						{onEdit && <RenderTitle title={'Show workflow to positions'} />}
						{onEdit && (
							<div className={cls.selectComponentContainer}>
								
									<MultiselectWithSearchR
										labelsTag={true}
										tagCloseButtonType={'X' /* | "X" */}
										tagEmplacement={'inlined'}
										items={positions}
										itemType={'checkboxes'}
										checkboxEmplacement={'left'}
										initialSelectedItems={initialSelectedPositions}
										className={cls.multiSelectContainer}
										inputClassName={cls.multiSelectSearchInput}
										molclassname={cls.multiSelectMenuOverlayList}
										moliclassname={cls.multiSelectMenuOverlayListItem}
										placeholder={'Select...'}
										onChange={selection => {
											setSelectedPositions(selection);
										}}
									>
										<FontAwesomeIcon icon={faChevronDown} style={{marginRight:'10px'}} />
									</MultiselectWithSearchR>
								

								{errorMessage?.length > 0 && <span className={cls.errorContainer}>{errorMessage}</span>}
							</div>
						)}
						<br />
						<br />
						{processesList.length > 0 && <RenderTitle title={'Select Another Workflow to Edit'} />}
						{processesList.length > 0 && (
							<Select
								onFocus={() => setMessage('')}
								isSearchable={false}
								key={'Workflow'}
								styles={customStyles}
								placeholder={'Select workflow to edit...'}
								components={{ DropdownIndicator }}
								options={processesList}
								onChange={select => {
									if (select.label !== 'New Workflow') {
										setSelectedProc(select.process);
										setNameValue(select.process.name);
										setDescriptionValue(select.process.description);
										store.dispatch(setSelectedProcess(select.process));
										setOnEdit(true);
									} else {
										store.dispatch(setSelectedProcess({ description: '', id: '', is_pinned: false, name: '', user_id: '', registered: false, type: false, steps: [] }));
										setSelectedProc({ description: '', id: '', is_pinned: false, name: '', user_id: '', registered: false, type: false, steps: [] });
										setNameValue('');
										setDescriptionValue('');
										setOnEdit(false);
									}
								}}
							/>
						)}
					</OptionSection>
					{error?.length > 0 && <MessageContainer message={error} onClick={() => setError('')} />}
					{message?.length > 0 && (
						<span className={cls.messageContainer} onClick={() => setMessage('')}>
							{message}
						</span>
					)}
				</div>
				<div className={onBoardingCompleted ? cls.controls : cls.controls2}>
					{onBoardingCompleted && <Button title={'Close'} clicked={onClose} />}
					{!onBoardingCompleted && <Button light title={'Back'} clicked={onClose} />}
					{!onEdit && onBoardingCompleted && <Button light title={'Save'} loading={loading} clicked={createProcess} />}

					<div style={{ display: 'flex' }}>
						{!onEdit && !onBoardingCompleted && (
							<Button
								title={'Skip'}
								loading={sLoading}
								clicked={() => {
									setSloading(true);
									onSkip();
								}}
							/>
						)}
						{!onEdit && !onBoardingCompleted && <Button title={'Create'} loading={loading} clicked={createProcess} />}
					</div>
					{onEdit && <Button light title={'Update'} loading={loading} clicked={onSharePressed} />}
					{!onBoardingCompleted && (onEdit || processesList.length > 0) && <Button light title={'Skip'} clicked={onCreate} />}
				</div>
			</div>
		</ModalR>
	);
};
export const OptionSection = props => {
	const { children } = props;
	return <div className={cls.optionSection}>{children}</div>;
};

const mapStateToProps = store => {
	const { companyProcesses, companyPositionsArray, allModules, components, onBoardingCompleted, companyData } = store.flowReducer;
	return { companyProcesses, companyPositionsArray, allModules, components, onBoardingCompleted, companyData };
};

export default connect(mapStateToProps)(ProcessOptionsModal);
